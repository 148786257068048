import { $api } from "@/services/constant";
import { HOST_API_V1 } from "@/services/env-init";
import { AxiosRequestConfig, BaseResponse } from "@/services/types";
import { ListTopic } from "./types";

const BASE_URL_V1_ADMIN_LEARNING_TOPIC = HOST_API_V1 + "admin/learning/topic";

export const getAdminLearningTopics = async (
  config?: AxiosRequestConfig
): Promise<BaseResponse<ListTopic[]>> => {
  const res = await $api().get(BASE_URL_V1_ADMIN_LEARNING_TOPIC, config);
  return res.data;
};

export const getAdminLearningTopicById = async (topicId: string) => {
  const res = await $api().get(
    `${BASE_URL_V1_ADMIN_LEARNING_TOPIC}/${topicId}`
  );
  return res.data;
};

export const postAdminLearningTopic = async (
  payload: AxiosRequestConfig["data"]
) => {
  const res = await $api().post(`${BASE_URL_V1_ADMIN_LEARNING_TOPIC}`, payload);
  return res.data;
};

export const putAdminLearningTopic = async (
  topicId: string,
  payload: AxiosRequestConfig["data"]
) => {
  const res = await $api().put(
    `${BASE_URL_V1_ADMIN_LEARNING_TOPIC}/${topicId}`,
    payload
  );
  return res.data;
};

export const deleteAdminLearningTopicById = async (topicId: string) => {
  const res = await $api().delete(
    `${BASE_URL_V1_ADMIN_LEARNING_TOPIC}/${topicId}`
  );
  return res.data;
};
